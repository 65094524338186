<template>
  <b-modal
    id="modal-cod-error"
    centered
    hide-footer
    hide-header
  >
    <BRow class="text-center text-black p-1">
      <BCol
        cols="12"
        class="d-flex justify-center"
      >
        <BImg
          class="w-25"
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
        />
      </BCol>
      <BCol cols="12">
        <h3 class="font-semibold my-2">
          Beberapa Order Belum Tepat
        </h3>
      </BCol>
      <BCol
        cols="12"
        class="mb-[5px]"
      >
        <span>Identifikasi teratas :</span>
      </BCol>
      <BCol cols="12">
        <div
          v-for="(item, index) in filteredCodErrorChild"
          :key="index"
          class="mb-[3px]"
        >
          <div v-if="index < 3">
            Data "Baris ke {{ item.index + 1 }}" Nominal COD {{ validateCOD(item.item.grandtotal) }}
          </div>
        </div>
        <div
          v-if="filteredCodErrorChild.length > 3"
          class="text-danger my-[5px]"
        >
          Silahkan cek orderan lainnya...
        </div>
      </BCol>
      <BCol
        cols="12"
        class="mt-2"
      >
        <BButton
          variant="primary"
          class="w-full"
          @click="$bvModal.hide('modal-cod-error')"
        >
          Perbaiki
        </BButton>
      </BCol>
    </BRow>
  </b-modal>
</template>
<script>
export default {
  props: {
    codErrorChild: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredCodErrorChild() {
      return this.codErrorChild
        .map((item, index) => ({ item, index }))
        .filter(({ item }) => {
          if (this.getMinMaxCod(item)) {
            return true
          }
          return false
        })
    },
  },
  methods: {
    validateCOD(value) {
      return Number(value) < 10000 ? 'minimal Rp 10.000' : 'maksimal Rp 5.000.000'
    },
    getMinMaxCod(value) {
      const grandTotal = Number(value.grandtotal)
      const condMinMaxCod = value.payment_method === 'COD' && value.grandtotal !== '' && (grandTotal < 10000 || grandTotal > 5000000)
      if (condMinMaxCod) {
        return true
      }
      return false
    },
  },
}
</script>
